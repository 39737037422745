import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import TableComponent from "../../common/table/table";
import { Button } from "reactstrap";
import { ToastShow } from "../../redux/toast/toast.action";
import { deleteUser, getAllUsers } from "../../services/authentication.service";
import swal from "sweetalert";

const UserList = ({ history, setToast }) => {
  const [data, setData] = useState([]);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [loader, setLoader] = useState(true);

  const getUserData = async (page, paginationPerPage, searchValue) => {
    setLoader(true);
    let rows = await getAllUsers({ page, paginationPerPage, searchValue });
    if (rows.data.data) {
      const totalDataCount = rows.data.data.count;
      rows = rows.data.data.data;
      const tableData = rows.map((r) => ({
        ...r,
        showButtons: true,
      }));
      setData(tableData);
      setPaginationTotalRows(totalDataCount);
    }
    setLoader(false);
  };

  const deleteData = async (id) => {
    swal({
      text: "Are you sure that you want to delete this user?",
      buttons: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let deletedData = await deleteUser(id);
        const arrangeData = data.filter((d) => d._id !== id);
        setToast("User deleted!!!");
        deletedData && setData(arrangeData);
      }
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row["name"],
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => (row["phone_number"] ? row["phone_number"] : "-"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => (row["email"] ? row["email"] : "-"),
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row) => {
        return (
          <div>
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "5px" }}
              onClick={() => history.push(`/view-user/${row._id}`)}
            >
              <i className="fa fa-eye"></i>
            </button>
            <button
              className="btn btn-primary btn-sm"
              style={{ marginRight: "5px" }}
              onClick={() => history.push(`/edit-user/${row._id}`)}
            >
              <i className="fa fa-edit"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => deleteData(row._id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">User</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-2 list-header">
                    <div className="col-sm-6">
                      <h4>All Users</h4>
                    </div>
                    <div className="col-sm-6">
                      <div className="float-right">
                        <Link to="add-user">
                          <Button color="primary">Add User</Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  {data && (
                    <TableComponent
                      title="All Users"
                      columns={columns}
                      data={data}
                      paginationTotalRows={paginationTotalRows}
                      fetchData={(page, paginationPerPage, searchValue) =>
                        getUserData(page, paginationPerPage, searchValue)
                      }
                      loader={loader}
                      searchPlaceHolder="Search user..."
                    ></TableComponent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(UserList);
