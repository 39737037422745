/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Input,
  CardBody,
  Button,
  FormGroup,
  Label,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import {
  createCategory,
  getParticularCategory,
  updateCategory,
} from "../../services/category.service";
import { getAllProduct } from "../../services/product.service";

import DropZone from "../../common/dropzone";
import { connect } from "react-redux";
import { ToastShow } from "../../redux/toast/toast.action";

const initial_state = {
  product_category_name: "",
  product_category_image: [],
  product: [],
};

const CategoryForm = (props) => {
  const { setToast } = props;
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(initial_state);
  const [id, setId] = useState(-1);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (props?.match?.params.id) {
      setId(props.match.params.id);
      getCategory(props.match.params.id);
    }
  }, [props.match]);

  useEffect(() => {
    getProductData();
  }, []);

  const getCategory = async (id) => {
    const res = await getParticularCategory(id);
    delete res.data.data["product"];

    const data = res.data.data;

    if (data !== []) {
      if (data.category_image !== null) {
        setInitialValues({
          ...initialValues,
          ...data,
          product_category_image: data.product_category_image
            ? [
                {
                  name: data.product_category_name,
                  preview:
                    process.env.REACT_APP_API_URL + data.product_category_image,
                },
              ]
            : [],
        });
      } else {
        setInitialValues({
          ...initialValues,
          ...res.data.data,
          product_category_image: [],
        });
      }
    }
  };

  const getProductData = async () => {
    const options = await getAllProduct({
      page: 1,
      paginationPerPage: 10,
      searchValue: "",
    });
    if (options.data.data) {
      const newProductList = [];
      options?.data?.data?.data.forEach((item) => {
        newProductList.push({
          value: item._id,
          label: item.product_name,
        });
      });
      setProductList(newProductList);
    }
  };

  const validationSchema = Yup.object().shape({
    product_category_name: Yup.string()
      .min(3, `Category name has to be at least 2 characters`)
      .required("Category name is required"),
    product_category_image: Yup.array().test(
      "product_category_image",
      "Category image is required",
      (value) => {
        let toggle = true;
        if (!value || value.length === 0) {
          toggle = false;
        }
        return toggle;
      }
    ),
    product: Yup.array(),
  });

  const submitEvent = async (value) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("product_category_name", value.product_category_name);
    if (value.product_category_image[0])
      formData.append(
        "product_category_image",
        value.product_category_image[0]
      );

    if (value.product && id === -1) {
      value.product.forEach((v) => {
        formData.append("product", v.value);
      });
    }

    if (id !== -1) {
      const res = await updateCategory(id, formData);
      if (res.data.response_type === "success") {
        setToast("Category Updated!!!");
        history.push("/category");
      }
      setLoader(false);
      setToast(res.data.message);
    } else {
      const res = await createCategory(formData);
      if (res.data.response_type === "success") {
        setToast("Category Created!!!");
        history.push("/category");
      }
      setLoader(false);
      setToast(res.data.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submitEvent(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className="content-wrapper">
            <Form>
              <CardBody>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="category name">Name</Label>
                      <Field
                        as={Input}
                        type="text"
                        placeholder="Categorye Name"
                        name="product_category_name"
                      />
                      <ErrorMessage name="product_category_name">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor="category image">Category Image</Label>
                      <InputGroup>
                        <DropZone
                          name="product_category_image"
                          files={values.product_category_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                      <ErrorMessage name="product_category_image">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                {id === -1 && (
                  <Row>
                    <Col sm={12} md={6}>
                      <FormGroup>
                        <Label htmlFor="product">Select Product</Label>
                        <Select
                          name="product"
                          options={productList}
                          isMulti
                          onChange={(e) => {
                            setFieldValue("product", e);
                          }}
                        />
                      </FormGroup>
                      <ErrorMessage name="product">
                        {(msg) => <span style={{ color: "red" }}>{msg}</span>}
                      </ErrorMessage>
                    </Col>
                  </Row>
                )}
              </CardBody>
              <div className="card-footer text-center">
                <Button
                  type="submit"
                  color="primary"
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <i className="fas fa-3x fa-sync-alt fa-spin mr-2"></i>
                  ) : (
                    ""
                  )}
                  {id === -1 ? <>Add Category</> : <>Update Category</>}
                </Button>
                <span className="ml-3">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      history.push("/category");
                    }}
                  >
                    Back
                  </Button>
                </span>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(CategoryForm);
