/* eslint-disable react-hooks/exhaustive-deps */
import DefaultLayout from "./common/default-layout";
import Dashboard from "./components/dashboard";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./components/authentication/login";
import { selectToken } from "../src/redux/token/token.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import StoreList from "./components/store/list";
import StoreForm from "./components/store/form";
import CategoryView from "./components/category/view";
import CategoryList from "./components/category/list";
import CategoryForm from "./components/category/form";
import UserList from "./components/user/list";
import UserForm from "./components/user/form";
import ProductList from "./components/product/list";
import ProductForm from "./components/product/form";
import ToastNotification from "./common/toast-notification";
import { useEffect } from "react";
import StoreView from "./components/store/view";
import ProductView from "./components/product/view";
import UserView from "./components/user/view";
import ForgetPassword from "./components/authentication/login/forgtePassword";
import EditProfile from "./components/edit_profile/form";
import ThemeSetting from "./components/theme_setting";
import { getThemeSetting } from "./services/theme_setting.service";
import { setThemeSetting } from "./redux/theme_setting/theme.action";
import AboutUs from "./components/about";
import TermsCondition from "./components/terms_condition";
import Verification from "./components/authentication/login/verification";
import PrivacyPolicy from "./components/privacy_policy";
import TermsAndConditionDetails from "./components/terms_condition/terms_condition";
import FacebookDataDeletionInstruction from "./components/facebook_data_deletion";
import CategoryViewProduct from "./components/category/view-product";

const RouteRapper = (component, props) => {
  return <DefaultLayout>{component}</DefaultLayout>;
};

function App({ token, setThemeSetting }) {
  useEffect(() => {
    document.title = process.env.REACT_APP_API_TITLE;
    getSetting();
  }, []);

  const getSetting = async () => {
    const res = await getThemeSetting();
    if (res.data.response_type === "success") {
      setThemeSetting(res.data.data);
    }
  };

  return (
    <>
      <ToastNotification />
      <Switch>
        <Route exact path="/login" name="Index Page" component={Login} />

        <Route
          exact
          path="/forget_password/:token"
          component={ForgetPassword}
        />

        <Route exact path="/verification/:token" component={Verification} />

        <Route exact path="/privacy-policy" component={PrivacyPolicy} />

        <Route
          exact
          path="/terms-and-condition"
          component={TermsAndConditionDetails}
        />

        <Route
          exact
          path="/facebook-data-deletion"
          component={FacebookDataDeletionInstruction}
        />

        {token === null ? <Redirect from="/" to="/login"></Redirect> : null}

        <Route
          exact
          path="/edit-profile"
          name="Edit Profile"
          component={() => RouteRapper(<EditProfile />)}
        />

        <Route
          exact
          path="/theme-setting"
          name="Theme Setting"
          component={() => RouteRapper(<ThemeSetting />)}
        />

        <Route
          exact
          path="/aboutUs"
          name="AboutUs"
          component={() => RouteRapper(<AboutUs />)}
        />

        <Route
          exact
          path="/terms-condition"
          name="TermsCondition"
          component={() => RouteRapper(<TermsCondition />)}
        />

        <Route
          exact
          path="/store"
          name="Store Page"
          component={() => RouteRapper(<StoreList />)}
        />

        <Route
          exact
          path="/add-store"
          name="Store Page"
          component={() => RouteRapper(<StoreForm />)}
        />

        <Route
          exact
          path="/edit-store/:id"
          name="Edit Store"
          component={(props) => RouteRapper(<StoreForm {...props} />)}
        />

        <Route
          exact
          path="/view-store/:id"
          name="View Store"
          component={(props) => RouteRapper(<StoreView {...props} />)}
        />

        <Route
          exact
          path="/category"
          name="Category Page"
          component={() => RouteRapper(<CategoryList />)}
        />

        <Route
          exact
          path="/add-category"
          name="Category Page"
          component={() => RouteRapper(<CategoryForm />)}
        />

        <Route
          exact
          path="/edit-category/:id"
          name="Edit Category"
          component={(props) => RouteRapper(<CategoryForm {...props} />)}
        />

        <Route
          exact
          path="/view-category/:id"
          name="View Category"
          component={(props) => RouteRapper(<CategoryView {...props} />)}
        />

        <Route
          exact
          path="/view-category-product/:id"
          name="View Category Product"
          component={(props) => RouteRapper(<CategoryViewProduct {...props} />)}
        />

        <Route
          exact
          path="/product"
          name="Product Page"
          component={() => RouteRapper(<ProductList />)}
        />

        <Route
          exact
          path="/add-product"
          name="Product Page"
          component={() => RouteRapper(<ProductForm />)}
        />

        <Route
          exact
          path="/edit-product/:id"
          name="Edit Product"
          component={(props) => RouteRapper(<ProductForm {...props} />)}
        />

        <Route
          exact
          path="/view-product/:id"
          name="view Product"
          component={(props) => RouteRapper(<ProductView {...props} />)}
        />

        <Route
          exact
          path="/user"
          name="User Page"
          component={(otherData) => RouteRapper(<UserList {...otherData} />)}
        />

        <Route
          path="/add-user"
          name="User Add Page"
          component={(otherData) => RouteRapper(<UserForm {...otherData} />)}
        />

        <Route
          exact
          path="/edit-user/:id"
          name="User Edit Page"
          component={(otherData) => RouteRapper(<UserForm {...otherData} />)}
        />

        <Route
          exact
          path="/view-user/:id"
          name="User view Page"
          component={(otherData) => RouteRapper(<UserView {...otherData} />)}
        />

        <Route
          path="/"
          name="Home Page"
          component={() => RouteRapper(<Dashboard />)}
        />
      </Switch>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  token: selectToken,
});

const mapDispatchToProps = (dispatch) => ({
  setThemeSetting: (theme) => dispatch(setThemeSetting(theme)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
