import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../axios/config";

export const createCategory = (data) => {
  return axiosPost("/product_category/add", data);
};

export const getAllCategory = (data) => {
  return axiosGet("/product_category/", data);
};

// export const getAllStores = (data) => {
//   return axiosGet("/category/list", data);
// };

export const getParticularCategory = (data) => {
  return axiosPost(`/product_category/view/${data}`);
};

export const deleteCategoty = (data) => {
  return axiosDelete(`/product_category/delete/${data}`);
};

export const updateCategory = (id, data) => {
  return axiosPatch(`/product_category/update/${id}`, data);
};

export const addProduct = (id, data) => {
  return axiosPatch(`/product_category/add_product/${id}`, data);
};

export const removeProduct = (id, data) => {
  return axiosPatch(`/product_category/remove_product/${id}`, data);
};
