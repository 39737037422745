/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Input,
  CardBody,
  Button,
  FormGroup,
  Label,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import {
  createStore,
  getParticularStore,
  updateStore,
} from '../../services/store.service';
import DropZone from '../../common/dropzone';
import { connect } from 'react-redux';
import { ToastShow } from '../../redux/toast/toast.action';

const initial_state = {
  store_name: '',
  store_image: [],
  store_background_image: [],
  store_desc: '',
  store_link: '',
};

const StoreForm = (props) => {
  const { setToast } = props;
  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [initialValues, setInitialValues] = useState(initial_state);
  const [id, setId] = useState(-1);

  useEffect(() => {
    if (props?.match?.params.id) {
      setId(props.match.params.id);
      getStore(props.match.params.id);
    }
  }, [props.match]);

  const getStore = async (id) => {
    const res = await getParticularStore(id);
    delete res.data.data['product'];

    const data = res.data.data;

    if (data !== []) {
      if (data.store_image || data.store_background_image) {
        setInitialValues({
          ...initialValues,
          ...res.data.data,
          store_image: data.store_image
            ? [
                {
                  name: data.store_name,
                  preview: process.env.REACT_APP_API_URL + data.store_image,
                },
              ]
            : [],
          store_background_image: data.store_background_image
            ? [
                {
                  name: data.store_name,
                  preview:
                    process.env.REACT_APP_API_URL + data.store_background_image,
                },
              ]
            : [],
        });
      } else {
        setInitialValues({
          ...initialValues,
          ...res.data.data,
          store_image: [],
          store_background_image: [],
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    store_name: Yup.string()
      .min(3, `Store name has to be at least 2 characters`)
      .required('Store name is required'),
    store_desc: Yup.string().required('Description is required'),
    store_link: Yup.string().required('Link is required'),
    store_image: Yup.array().test(
      'store_image',
      'Store image is required',
      (value) => {
        let toggle = true;
        if (!value || value.length === 0) {
          toggle = false;
        }
        return toggle;
      }
    ),
    store_background_image: Yup.array().test(
      'store_background_image',
      'Background image is required',
      (value) => {
        let toggle = true;
        if (!value || value.length === 0) {
          toggle = false;
        }
        return toggle;
      }
    ),
  });

  const submitEvent = async (value) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('store_name', value.store_name);
    formData.append('store_desc', value.store_desc);
    formData.append('store_link', value.store_link);
    if (value.store_background_image[0])
      formData.append(
        'store_background_image',
        value.store_background_image[0]
      );
    if (value.store_image[0])
      formData.append('store_image', value.store_image[0]);

    if (id !== -1) {
      const res = await updateStore(id, formData);
      if (res.data.response_type === 'success') {
        setToast('Store Updated!!!');
        history.push('/store');
      }
      setLoader(false);
      setToast(res.data.message);
    } else {
      const res = await createStore(formData);
      if (res.data.response_type === 'success') {
        setToast('Store Created!!!');
        history.push('/store');
      }
      setLoader(false);
      setToast(res.data.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        submitEvent(values);
      }}
    >
      {({ values, setFieldValue }) => {
        return (
          <div className='content-wrapper'>
            <Form>
              <CardBody>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='store name'>Name</Label>
                      <Field
                        as={Input}
                        type='text'
                        placeholder='Store Name'
                        name='store_name'
                      />
                      <ErrorMessage name='store_name'>
                        {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='store desc'>Description</Label>
                      <Input
                        type='textarea'
                        placeholder='Store Description'
                        name='store_desc'
                        value={values.store_desc}
                        rows={2}
                        onChange={(e) =>
                          setFieldValue('store_desc', e.target.value)
                        }
                      />
                      <ErrorMessage name='store_desc'>
                        {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='background image'>Background Image</Label>
                      <InputGroup>
                        <DropZone
                          name='store_background_image'
                          files={values.store_background_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                      <ErrorMessage name='store_background_image'>
                        {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                  <Col sm={12} md={6}>
                    <FormGroup>
                      <Label htmlFor='background image'>Store Image</Label>
                      <InputGroup>
                        <DropZone
                          name='store_image'
                          files={values.store_image}
                          setFiles={setFieldValue}
                          number={1}
                          id={-1}
                          multipleOption={false}
                        />
                      </InputGroup>
                      <ErrorMessage name='store_image'>
                        {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                      </ErrorMessage>
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label htmlFor='store link'>Link</Label>
                  <Field
                    as={Input}
                    type='text'
                    placeholder='Store Link'
                    name='store_link'
                  />
                  <ErrorMessage name='store_link'>
                    {(msg) => <span style={{ color: 'red' }}>{msg}</span>}
                  </ErrorMessage>
                </FormGroup>
              </CardBody>
              <div className='card-footer text-center'>
                <Button
                  type='submit'
                  color='primary'
                  disabled={loader ? true : false}
                >
                  {loader ? (
                    <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                  ) : (
                    ''
                  )}
                  {id === -1 ? <>Add Store</> : <>Update Store</>}
                </Button>
                <span className='ml-3'>
                  <Button
                    type='button'
                    color='secondary'
                    onClick={() => {
                      history.push('/store');
                    }}
                  >
                    Back
                  </Button>
                </span>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(StoreForm);
