/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "reactstrap";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  addProduct,
  getParticularCategory,
  removeProduct,
} from "../../services/category.service";
import { getAllProduct } from "../../services/product.service";
import DataTable from "react-data-table-component";
import { ToastShow } from "../../redux/toast/toast.action";
import { connect } from "react-redux";
import swal from "sweetalert";

const CategoryViewProduct = (props) => {
  const { setToast } = props;
  const history = useHistory();
  const [categoryData, setCategoryData] = useState({});
  const [productList, setProductList] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (props?.match?.params.id) {
      getCategory(props.match.params.id);
      getProductData();
      setLoader(false);
    } else {
      history.push("/category");
    }
  }, []);

  const getProductData = async () => {
    const options = await getAllProduct({
      page: 1,
      paginationPerPage: 10,
      searchValue: "",
      productCategory: props.match.params.id,
    });
    if (options.data.data) {
      const newProductList = [];
      options?.data?.data?.data.forEach((item) => {
        newProductList.push({
          value: item._id,
          label: item.product_name,
        });
      });
      setProductList(newProductList);
    }
  };

  const getCategory = async (id) => {
    const res = await getParticularCategory(id);

    const data = res.data.data;
    setCategoryData(data);
  };

  const addCategoryProduct = async () => {
    const formData = new FormData();
    if (selectedProductList.length === 0) {
      setToast("Please select some product");
      return;
    }
    selectedProductList.forEach((el) => {
      formData.append("product_id", el.value);
    });
    const res = await addProduct(props.match.params.id, formData);
    if (res.data.response_type === "success") {
      setCategoryData(res.data.data);
      getProductData();
      setSelectedProductList([]);
      setToast("Product added!!!");
    } else {
      setToast(res.data.message);
    }
  };

  const removeCategoryProduct = async (id) => {
    swal({
      text: "Are you sure that you want to delete this product?",
      buttons: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const formData = new FormData();
        formData.append("product_id", id);
        const res = await removeProduct(props.match.params.id, formData);
        if (res.data.response_type === "success") {
          setCategoryData(res.data.data);
          getProductData();
          setToast("Product removed!!!");
        } else {
          setToast(res.data.message);
        }
      }
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row["product_name"],
    },
    {
      name: "Price",
      selector: (row) => row["product_price"],
    },
    {
      name: "Size",
      selector: (row) => (row["product_size"] ? row["product_size"] : "-"),
    },
    {
      name: "Color",
      selector: (row) => (row["product_color"] ? row["product_color"] : "-"),
    },
    {
      name: "Action",
      button: true,
      cell: (row) => {
        return (
          <div>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => history.push(`/view-product/${row._id}`)}
              style={{ marginRight: "5px" }}
            >
              <i className="fa fa-eye"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={(e) => removeCategoryProduct(row._id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/category">Category</Link>
                </li>
                <li className="breadcrumb-item active">
                  {categoryData?.product_category_name}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="list-header">
                      <h4>Categories Product</h4>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-10">
                      <Select
                        name="product"
                        options={productList}
                        value={selectedProductList}
                        isMulti
                        onChange={(e) => {
                          setSelectedProductList(e);
                        }}
                      />
                    </div>
                    <div className="col-sm-2">
                      <div className="float-right">
                        <Button color="primary" onClick={addCategoryProduct}>
                          Add Product
                        </Button>
                      </div>
                    </div>
                    {categoryData && (
                      <DataTable
                        title=""
                        columns={columns}
                        data={categoryData.product}
                        // paginationTotalRows={paginationTotalRows}
                        // fetchData={(page, paginationPerPage, searchValue) =>
                        //   getCategoryData(page, paginationPerPage, searchValue)
                        // }
                        loader={loader}
                        searchPlaceHolder="Search category..."
                      ></DataTable>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setToast: (message) => dispatch(ToastShow({ message })),
});

export default connect(null, mapDispatchToProps)(CategoryViewProduct);
