import { axiosDelete, axiosGet, axiosPatch, axiosPost } from "../axios/config";

export const createProduct = (data) => {
  return axiosPost("/product/add", data);
};

export const getAllProduct = (data) => {
  return axiosGet("/product/admin/", data);
};

export const getAllProducts = (data) => {
  return axiosGet("/product/list", data);
};

export const getParticularProduct = (data) => {
  return axiosPost(`/product/view/${data}`);
};

export const deleteProduct = (data) => {
  return axiosDelete(`/product/delete/${data}`);
};

export const updateProduct = (id, data) => {
  return axiosPatch(`/product/update/${id}`, data);
};
