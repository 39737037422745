import { axiosDelete, axiosGet, axiosPatch, axiosPost } from '../axios/config';

export const createStore = (data) => {
  return axiosPost('/store/add', data);
};

export const getAllStore = (data) => {
  return axiosGet('/store/', data);
};

export const getAllStores = (data) => {
  return axiosGet('/store/list', data);
};

export const getParticularStore = (data) => {
  return axiosPost(`/store/view/${data}`);
};

export const deleteStore = (data) => {
  return axiosDelete(`/store/delete/${data}`);
};

export const updateStore = (id, data) => {
  return axiosPatch(`/store/update/${id}`, data);
};
