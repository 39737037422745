/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getUserVerificationData } from '../../../services/verification.service';

const Verification = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (props?.match?.params?.token) {
      getUserData(props.match.params.token);
    }
  }, [props.match.params.token]);

  const getUserData = async (data) => {
    const res = await getUserVerificationData({ token: data });
    if (res.data.response_type === 'success') {
      setData(res.data);
      setLoader(false);
      // setTimeout(() => {
      //   history.push('/login');
      // }, 5000);
    } else if (res.data.response_type === 'error') {
      setData(res.data);
      setLoader(false);
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    }
  };
  return (
    <div>
      <div className='login-screen'>
        <div className='wrapper'>
          <div className='row'>
            <div className='col-md-6 col-sm-12 left-img-wrap'>
              <img src='/images/Tambe_app_icn.png' alt='logo'></img>
            </div>
            <div className='col-md-6 col-sm-12'>
              <div className='hold-transition login-page'>
                <div className='login-box'>
                  <div className='login-logo'>
                    <h1>
                      Welcome To,{' '}
                      <img src='/images/tambe_logo_2.png' alt='logo'></img>
                    </h1>
                    {loader ? (
                      <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
                    ) : (
                      <>
                        {data.response_type === 'success' ? (
                          <div className='mt-5'>
                            <span style={{ fontSize: '20px' }}>
                              Verified Successfully
                            </span>
                            {', '}
                            <a
                              style={{ fontSize: '18px', color: '#007bff' }}
                              href='https://tambeapp.page.link/dynamiclink'
                            >
                              Click Here to Log-in
                            </a>
                          </div>
                        ) : (
                          <div className='mt-5'>
                            <h5>{data.message}</h5>
                          </div>
                        )}
                      </>
                    )}{' '}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {console.log('component', data)}
      {loader ? (
        <i className='fas fa-3x fa-sync-alt fa-spin mr-2'></i>
      ) : (
        <>
          {data.response_type === 'success' ? (
            <>
              <h1>Welcome</h1>
              <p>Verified Successfully</p>
            </>
          ) : (
            <>
              {console.log('data', data)}
              <p>{data.message}</p>
            </>
          )}
        </>
      )} */}
    </div>
  );
};

export default Verification;
